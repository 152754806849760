import { FormsService } from '@/api/api.service'
import {
  FORMS_GET_FORM,
  FORMS_GET_FORMS,
  FORMS_CREATE_FORM,
  FORMS_UPDATE_FORM
} from '@/store/actions.type'
import {
  FORMS_SET_FORM,
  FORMS_SET_FORMS,
  FORMS_GET_START,
  FORMS_GET_END
} from '@/store/mutations.type'

const initialState = {
  forms: [],
  form: {},
  isLoading: true
}

export const state = Object.assign({}, initialState)

const actions = {
  async [FORMS_GET_FORMS]({ commit }) {
    commit(FORMS_GET_START)
    const { data } = await FormsService.getForms()
    commit(FORMS_SET_FORMS, data)
    commit(FORMS_GET_END)
  },

  async [FORMS_GET_FORM]({ commit }, formId) {
    commit(FORMS_GET_START)
    const { data } = await FormsService.getForm(formId)
    commit(FORMS_SET_FORM, data)
    commit(FORMS_GET_END)
  },

  async [FORMS_CREATE_FORM]({ commit }, form) {
    const { data } = await FormsService.createForm(form)
    return data
  },
  async [FORMS_UPDATE_FORM]({ commit }, { form }) {
    await FormsService.updateForm(form)
  }
}

const mutations = {
  [FORMS_GET_START](state) {
    state.isLoading = false
  },
  [FORMS_GET_END](state) {
    state.isLoading = false
  },
  [FORMS_SET_FORMS](state, forms) {
    state.forms = forms
  },
  [FORMS_SET_FORM](state, form) {
    state.form = form
  }
}

const getters = {
  getFormsForms: state => {
    return state.forms
  },
  getFormsForm: state => {
    return state.form
  },
  getFormsIsLoading: state => {
    return state.isLoading
  },
  getFormsTransition: state => {
    return state.transition
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
