import { formatDistanceToNow } from '@/helpers'
import { es } from 'date-fns/esm/locale'
export default function(isoString) {
  if (!isoString) return '-'

  const utcDate = Date.parse(isoString)
  const formatted = formatDistanceToNow(utcDate,
    { locale: es })

  return formatted
}
