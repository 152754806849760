<template>
  <v-app-bar color="info" app clipped-left style="z-index: 6;">

    <v-app-bar-nav-icon @click.stop="collapseSidebar"></v-app-bar-nav-icon>

    <v-toolbar-title class="mr-12 align-center">
        <img src="/img/theme/logo_map.png" style="height: 63px; margin-top: 3px;">
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn tile style="margin-right: 1rem;">INICIO</v-btn>
    <!-- <v-btn tile style="margin-right: 1rem;">ANALITICA</v-btn>
    <v-btn tile style="margin-right: 1rem;">GALERIA</v-btn> -->

    <v-menu bottom>
      <template v-slot:activator="{ on }">
        <v-btn tile v-on="on">
          <v-icon>mdi-account-circle</v-icon>
          <span style="font-size: 10px; text-transform: initial;">Administrator</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="myProfile">
          <v-list-item-title>Mi perfil</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Cerrar sesión</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>
  </v-app-bar>
</template>

<script>
import AppConfig from '@/constants/app-config'
import { to } from '@/helpers'
import { SESSION_LOGOUT, SETTINGS_COLLAPSE_SIDEBAR } from '@/store/actions.type'
import { mapGetters } from 'vuex'
export default {
  name: 'TheHeader',
  props: {},
  data() {
    return {
      brand: AppConfig.brand
    }
  },
  computed: {
    ...mapGetters(['getSessionUser'])
  },
  methods: {
    async logout() {
      await to(this.$store.dispatch(SESSION_LOGOUT))
      this.$router.push('/session/login')
    },
    myProfile() {
      this.$router.push('/my-profile')
    },
    collapseSidebar() {
      this.$store.dispatch(SETTINGS_COLLAPSE_SIDEBAR)
    },
    getInitials(displayName) {
      if (!displayName) return ''
      return displayName.charAt(0)
    }
  },
  components: {}
}
</script>
