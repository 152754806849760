// Globally register all base components for convenience, because they will be used very frequently.
import Vue from 'vue'

const requireComponent = require.context(
  // The relative path of the components folder
  './base',
  // Whether or not to look in subfolders
  true,
  // Only include "base-" or prefixed .vue files
  /(base-)[\w-]+\.vue$/
)

// For each matching file name...
requireComponent.keys().forEach(fileName => {
  // Get the component config
  const componentConfig = requireComponent(fileName)
  // Get the PascalCase version of the component name

  const componentName = fileName
    // Remove the "./" and subfolder from the beginning
    .replace(/^\.\/[\w-]+\//, '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '')

  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig)
})
