import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import es from 'vuetify/es5/locale/es'
import Vuetify from 'vuetify/lib'
// store
// import { store } from '@/store'
import primaryTheme from '@/themes/primary'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: primaryTheme
      // light: store.getters.selectedTheme.theme,
      // dark: store.getters.selectedTheme.theme
    }
  }
})
