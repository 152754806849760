import { FieldsService } from '@/api/api.service'

import {
  FIELDS_SELECT_FIELD, FIELDS_SET_FIELDS, FIELDS_SET_FIELD_OPTIONS,
  FIELDS_SET_FIELD_TYPES, FIELDS_SET_FORMS
} from '@/store/mutations.type'

import {
  FIELDS_CREATE_FIELD, FIELDS_CREATE_FIELD_OPTION, FIELDS_GET_FIELD, FIELDS_GET_FIELDS, FIELDS_GET_FIELD_OPTIONS, FIELDS_GET_FIELD_TYPES, FIELDS_GET_FORMS, FIELDS_REMOVE_FIELD, FIELDS_REMOVE_FIELD_OPTION, FIELDS_UPDATE_FIELD
} from '@/store/actions.type'

const initialState = {
  fields: [],
  selectedField: {},
  fieldTypes: [],
  selectedFieldOptions: []
}

export const state = Object.assign({}, initialState)

export const actions = {
  async [FIELDS_CREATE_FIELD] (context, field) {
    const response = await FieldsService.createField(field)
    if (response.status === 200) {
      context.commit(FIELDS_CREATE_FIELD, response.data)
    }
    return response
  },
  async [FIELDS_REMOVE_FIELD] (context, field) {
    const response = await FieldsService.removeField(field)
    if (response.status === 200) {
      context.commit(FIELDS_REMOVE_FIELD, field)
    }
  },
  async [FIELDS_UPDATE_FIELD] (context, field) {
    const response = await FieldsService.updateField(field)
    if (response.status === 200) {
      context.commit(FIELDS_UPDATE_FIELD, response.data)
    }
  },
  async [FIELDS_GET_FIELDS] (context) {
    const response = await FieldsService.getFields()
    if (response.status === 200) {
      context.commit(FIELDS_SET_FIELDS, response.data)
    }
  },
  async [FIELDS_GET_FIELD] (context, fieldId) {
    const response = await FieldsService.getField(fieldId)
    if (response.status === 200) {
      context.commit(FIELDS_SELECT_FIELD, response.data)
    }
  },
  async [FIELDS_GET_FIELD_OPTIONS] (context, fieldId) {
    const response = await FieldsService.getFieldOptions(fieldId)
    if (response.status === 200) {
      context.commit(FIELDS_SET_FIELD_OPTIONS, response.data)
    }
  },
  async [FIELDS_CREATE_FIELD_OPTION] (context, params) {
    const response = await FieldsService.addOption(params.fieldId, params.value)
    if (response.status === 200) {
      context.commit(FIELDS_CREATE_FIELD_OPTION, response.data)
    }
  },
  async [FIELDS_REMOVE_FIELD_OPTION] (context, params) {
    const response = await FieldsService.removeOption(params.fieldId, params.option)
    if (response.status === 200) {
      context.commit(FIELDS_REMOVE_FIELD_OPTION, params)
    }
  },
  async [FIELDS_GET_FORMS] (context) {
    const response = await FieldsService.getForms()
    if (response.status === 200) {
      context.commit(FIELDS_SET_FORMS)
    }
  },
  async [FIELDS_GET_FIELD_TYPES] (context) {
    const response = await FieldsService.getFieldTypes()
    if (response.status === 200) {
      context.commit(FIELDS_SET_FIELD_TYPES, response.data)
    }
  }
}

export const mutations = {
  [FIELDS_CREATE_FIELD] (state, field) {
    state.fields.push(field)
  },
  [FIELDS_REMOVE_FIELD] (state, field) {
    var index = state.fields.findIndex(f => f.id === field.id)
    state.fields.splice(index, 1)
  },
  [FIELDS_UPDATE_FIELD] (state, field) {
    var index = state.fields.findIndex(f => f.id === field.id)
    state.fields[index] = field
  },
  [FIELDS_SET_FIELDS] (state, fields) {
    state.fields = fields
  },
  [FIELDS_SELECT_FIELD] (state, field) {
    state.selectedField = field
  },
  [FIELDS_SET_FIELD_OPTIONS] (state, options) {
    state.selectedFieldOptions = options
  },
  [FIELDS_CREATE_FIELD_OPTION] (state, option) {
    state.selectedFieldOptions.push(option)
  },
  [FIELDS_REMOVE_FIELD_OPTION] (state, params) {
    var index = state.selectedFieldOptions.findIndex(fo => fo.id === params.option.id)
    state.selectedFieldOptions.splice(index, 1)
  },
  [FIELDS_GET_FORMS] (state, forms) {
    state.forms = forms
  },
  [FIELDS_SET_FIELD_TYPES] (state, fieldTypes) {
    state.fieldTypes = fieldTypes
  }
}

export const getters = {
  getFields: state => {
    return state.fields
  },
  getSelectedField: state => {
    return state.selectedField
  },
  getForms: state => {
    return state.forms
  },
  getFieldTypes: state => {
    return state.fieldTypes
  },
  getFieldOptions: state => {
    return state.selectedFieldOptions
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
