import { SETTINGS_COLLAPSE_SIDEBAR, SETTINGS_HIDE_SIDEBAR } from '@/store/actions.type'
import { SETTINGS_TOGGLE_SIDEBAR } from '@/store/mutations.type'

const initialState = {
  collapseSidebar: false
}

export const state = Object.assign({}, initialState)

const actions = {
  async [SETTINGS_COLLAPSE_SIDEBAR](context) {
    context.commit(SETTINGS_TOGGLE_SIDEBAR)
  },
  async [SETTINGS_HIDE_SIDEBAR](context) {
    context.commit(SETTINGS_HIDE_SIDEBAR)
  }
}

const mutations = {
  [SETTINGS_TOGGLE_SIDEBAR](state) {
    state.collapseSidebar = !state.collapseSidebar
  },
  [SETTINGS_HIDE_SIDEBAR](state) {
    state.collapseSidebar = true
  }
}

const getters = {
  getSettingsCollapseSidebar: state => {
    return state.collapseSidebar
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
