import { WorkflowsService } from '@/api/api.service'
import {
  WORKFLOWS_GET_WORKFLOW,
  WORKFLOWS_GET_WORKFLOWS,
  WORKFLOWS_CREATE_WORKFLOW,
  WORKFLOWS_UPDATE_WORKFLOW,
  WORKFLOWS_GET_TRANSITIONS,
  WORKFLOWS_CREATE_TRANSITION,
  WORKFLOWS_DELETE_TRANSITION,
  WORKFLOWS_GET_WORKFLOW_TRANSITION,
  WORKFLOWS_UPDATE_TRANSITION
} from '@/store/actions.type'
import {
  WORKFLOWS_SET_WORKFLOW,
  WORKFLOWS_SET_WORKFLOWS,
  WORKFLOWS_GET_START,
  WORKFLOWS_GET_END,
  WORKFLOWS_SET_TRANSITIONS,
  WORKFLOWS_SET_WORKFLOW_TRANSITION
} from '@/store/mutations.type'

const initialState = {
  workflows: [],
  workflow: {},
  transitions: [],
  transition: {},
  isLoading: true
}

export const state = Object.assign({}, initialState)

function extractStates(transitions) {
  if (transitions.length === 0) return []
  const all = []
  for (const elem of transitions) {
    all.push(elem.to)
    all.push(elem.from)
  }
  const result = []
  const map = new Map()
  for (const elem of all) {
    if (!map.has(elem.id)) {
      map.set(elem.id, true)
      result.push({
        id: elem.id,
        label: elem.name,
        color: elem.color,
        statusTypeSlug: elem.statusType.slug
      })
    }
  }
  return result
}

function extractTransitions(transitions) {
  if (transitions.length === 0) return []
  const result = transitions.map(elem => {
    return {
      id: elem.id,
      label: elem.name,
      source: elem.from.id,
      target: elem.to.id
    }
  })
  return result
}

const actions = {
  async [WORKFLOWS_GET_WORKFLOWS]({ commit }) {
    commit(WORKFLOWS_GET_START)
    const { data } = await WorkflowsService.getWorkflows()
    commit(WORKFLOWS_SET_WORKFLOWS, data)
    commit(WORKFLOWS_GET_END)
  },

  async [WORKFLOWS_GET_WORKFLOW]({ commit }, workflowId) {
    commit(WORKFLOWS_GET_START)
    const { data } = await WorkflowsService.getWorkflow(workflowId)
    commit(WORKFLOWS_SET_WORKFLOW, data)
    commit(WORKFLOWS_GET_END)
  },
  async [WORKFLOWS_CREATE_WORKFLOW]({ commit }, workflow) {
    const { data } = await WorkflowsService.createWorkflow(workflow)
    return data
  },
  async [WORKFLOWS_UPDATE_WORKFLOW]({ commit }, workflow) {
    await WorkflowsService.updateWorkflow(workflow)
  },

  async [WORKFLOWS_GET_TRANSITIONS]({ commit }, workflowId) {
    commit(WORKFLOWS_GET_START)
    const { data } = await WorkflowsService.getWorkflowTransitions(workflowId)
    commit(WORKFLOWS_SET_TRANSITIONS, data)
    commit(WORKFLOWS_GET_END)
  },
  async [WORKFLOWS_GET_WORKFLOW_TRANSITION](
    { commit },
    { workflowId, transitionId }
  ) {
    commit(WORKFLOWS_GET_START)
    const { data } = await WorkflowsService.getWorkflowTransition(
      workflowId,
      transitionId
    )
    commit(WORKFLOWS_SET_WORKFLOW_TRANSITION, data)
    commit(WORKFLOWS_GET_END)
  },
  async [WORKFLOWS_CREATE_TRANSITION]({ commit }, { workflowId, transition }) {
    const { data } = await WorkflowsService.createTransition(
      workflowId,
      transition
    )
    return data
  },
  async [WORKFLOWS_UPDATE_TRANSITION](
    { commit },
    { workflowId, transitionId, transition }
  ) {
    const { data } = await WorkflowsService.updateTransition(
      workflowId,
      transitionId,
      transition
    )
    return data
  },
  async [WORKFLOWS_DELETE_TRANSITION](
    { commit },
    { workflowId, transitionId }
  ) {
    const { data } = await WorkflowsService.deleteTransition(workflowId, transitionId)
    return data
  }
}

const mutations = {
  [WORKFLOWS_GET_START](state) {
    state.isLoading = true
  },
  [WORKFLOWS_GET_END](state) {
    state.isLoading = false
  },
  [WORKFLOWS_SET_WORKFLOWS](state, workflows) {
    state.workflows = workflows
  },
  [WORKFLOWS_SET_WORKFLOW](state, workflow) {
    state.workflow = workflow
  },
  [WORKFLOWS_SET_TRANSITIONS](state, transitions) {
    state.transitions = transitions
  },
  [WORKFLOWS_SET_WORKFLOW_TRANSITION](state, transition) {
    state.transition = transition
  }
}

const getters = {
  getWorkflowsWorkflows: state => {
    return state.workflows
  },
  getWorkflowsWorkflow: state => {
    return state.workflow
  },
  getWorkflowsFormattedTransitions: state => {
    const raw = state.transitions
    const states = extractStates(raw)
    const transitions = extractTransitions(raw)
    return { states, transitions }
  },
  getWorkflowsIsLoading: state => {
    return state.isLoading
  },
  getWorkflowTransition: state => {
    return state.transition
  },
  getWorkflowTransitions: state => {
    return state.transitions
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
