import { TimelinesService } from '@/api/api.service'
import {
  TIMELINES_GET_TIMELINES,
  TIMELINES_GET_TIMELINE
} from '@/store/actions.type'
import { format } from '@/helpers'
import {
  TIMELINES_GET_START,
  TIMELINES_GET_END,
  TIMELINES_SET_TIMELINES,
  TIMELINES_SET_TIMELINE,
  TIMELINES_SET_SELECTED_DATE,
  TIMELINES_REMOVE_TIMELINE
} from '@/store/mutations.type'

const initialState = {
  timelines: [],
  timeline: {},
  selectedIsssue: {},
  isLoading: true,
  selectedDate: new Date()
}

export const state = Object.assign({}, initialState)

const actions = {
  async [TIMELINES_GET_TIMELINE]({ commit, getters }, userId) {
    commit(TIMELINES_GET_START)
    const params = {
      date: format(getters.getTimelinesSelectedDate, 'yyyy-MM-dd')
    }
    const { data } = await TimelinesService.getTimeline(userId, params)
    commit(TIMELINES_SET_TIMELINE, data)
    commit(TIMELINES_GET_END)
  },
  async [TIMELINES_GET_TIMELINES]({ commit, getters }) {
    commit(TIMELINES_GET_START)
    const params = {
      date: format(getters.getTimelinesSelectedDate, 'yyyy-MM-dd')
    }
    const { data } = await TimelinesService.getTimelines(params)
    commit(TIMELINES_GET_END)
    commit(TIMELINES_SET_TIMELINES, data)
  }
}

const mutations = {
  [TIMELINES_GET_START](state) {
    state.timeline = {}
    state.isLoading = true
  },
  [TIMELINES_GET_END](state) {
    state.isLoading = false
  },
  [TIMELINES_SET_TIMELINE](state, timeline) {
    for (let j = 0; j < timeline.events.length; j++) {
      const events = timeline.events[j]
      events.lat = events.latitude
      events.lng = events.longitude
    }
    const maxDate = new Date(
      Math.max.apply(
        null,
        timeline.events.map(function(elem) {
          return new Date(elem.trackedAt)
        })
      )
    )
    const minDate = new Date(
      Math.min.apply(
        null,
        timeline.events.map(function(elem) {
          return new Date(elem.trackedAt)
        })
      )
    )
    timeline.startDate = minDate
    timeline.endDate = maxDate
    state.timeline = timeline
    state.isLoading = false
  },
  [TIMELINES_SET_TIMELINES](state, timelines) {
    for (let i = 0; i < timelines.length; i++) {
      const user = timelines[i]
      for (let j = 0; j < user.events.length; j++) {
        const events = user.events[j]
        events.position = {
          lat: events.latitude,
          lng: events.longitude
        }
      }
    }
    state.timelines = timelines
    state.isLoading = false
  },
  [TIMELINES_SET_SELECTED_DATE](state, date) {
    state.selectedDate = date
  },
  [TIMELINES_REMOVE_TIMELINE](state, date) {
    state.timeline = {}
  }
}

const getters = {
  getTimelinesTimelines: state => {
    const sorted = [...state.timelines].sort(function(a, b) {
      return new Date(b.username) - new Date(a.username)
    })
    return sorted
  },
  getTimelinesTimeline: state => {
    return state.timeline
  },
  getTimelinesIsLoading: state => {
    return state.isLoading
  },
  getSelectedTimeline: state => {
    return state.selectedIsssue
  },
  getTimelinesSelectedDate() {
    return state.selectedDate
  },
  getTimelinesTimelineStartDate() {
    return state.timelineStartDate
  },
  getTimelinesTimelineEndDate() {
    return state.timelineEndDate
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
