import { IssueStatusService } from '@/api/api.service'
import {
  ISSUE_STATUS_GET_ISSUE_STATUS,
  ISSUE_STATUS_GET_ISSUE_STATUSES,
  ISSUE_STATUS_CREATE_ISSUE_STATUS,
  ISSUE_STATUS_UPDATE_ISSUE_STATUS,
  ISSUE_STATUS_GET_ISSUE_STATUS_TYPES
} from '@/store/actions.type'
import {
  ISSUE_STATUS_SET_ISSUE_STATUS,
  ISSUE_STATUS_SET_ISSUE_STATUSES,
  ISSUE_STATUS_STATUS_ISSUE_STATUS_TYPES,
  ISSUE_STATUS_GET_START,
  ISSUE_STATUS_GET_END
} from '@/store/mutations.type'

const initialState = {
  issueStatuses: [],
  issueStatus: {},
  issueStatusTypes: [],
  isLoading: true
}

export const state = Object.assign({}, initialState)

const actions = {
  async [ISSUE_STATUS_GET_ISSUE_STATUSES]({ commit }) {
    commit(ISSUE_STATUS_GET_START)
    const { data } = await IssueStatusService.getIssueStatuses()
    commit(ISSUE_STATUS_SET_ISSUE_STATUSES, data)
    commit(ISSUE_STATUS_GET_END)
  },

  async [ISSUE_STATUS_GET_ISSUE_STATUS]({ commit }, issueStatusId) {
    commit(ISSUE_STATUS_GET_START)
    const { data } = await IssueStatusService.getIssueStatus(issueStatusId)
    commit(ISSUE_STATUS_SET_ISSUE_STATUS, data)
    commit(ISSUE_STATUS_GET_END)
  },

  async [ISSUE_STATUS_CREATE_ISSUE_STATUS]({ commit }, issueStatus) {
    await IssueStatusService.createIssueStatus(issueStatus)
  },
  async [ISSUE_STATUS_UPDATE_ISSUE_STATUS]({ commit }, issueStatus) {
    await IssueStatusService.updateIssueStatus(issueStatus)
  },
  async [ISSUE_STATUS_GET_ISSUE_STATUS_TYPES]({ commit }) {
    commit(ISSUE_STATUS_GET_START)
    const { data } = await IssueStatusService.getIssueStatusTypes()
    commit(ISSUE_STATUS_STATUS_ISSUE_STATUS_TYPES, data)
    commit(ISSUE_STATUS_GET_END)
  }
}

const mutations = {
  [ISSUE_STATUS_GET_START](state) {
    state.isLoading = true
  },
  [ISSUE_STATUS_GET_END](state) {
    state.isLoading = false
  },
  [ISSUE_STATUS_SET_ISSUE_STATUSES](state, issueStatuses) {
    state.issueStatuses = issueStatuses
  },
  [ISSUE_STATUS_SET_ISSUE_STATUS](state, issueStatus) {
    state.issueStatus = issueStatus
  },
  [ISSUE_STATUS_STATUS_ISSUE_STATUS_TYPES](state, issueStatusTypes) {
    state.issueStatusTypes = issueStatusTypes
  }
}

const getters = {
  getIssueStatusIssueStatuses: state => {
    return state.issueStatuses
  },
  getIssueStatusIssueStatus: state => {
    return state.issueStatus
  },
  getIssueStatusIsLoading: state => {
    return state.isLoading
  },
  getIssueStatusIssueTypes: state => {
    return state.issueStatusTypes
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
