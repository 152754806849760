<template>
  <div class="app-default-layout">
      <the-header></the-header>
      <the-sidebar></the-sidebar>
    <v-content>
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
import TheHeader from '@/components/single/the-header/the-header'
import TheSidebar from '@/components/single/the-sidebar/the-sidebar'
export default {
  name: 'full',
  data() {
    return {}
  },
  components: {
    TheHeader,
    TheSidebar
  },
  computed: {}
}
</script>
