import ApiService from '@/api/api.service'
import AppConfig from '@/constants/app-config'
import vuetify from '@/plugins/vuetify'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/src/components/cluster'
import './register-service-worker'

// Global components
import '@/components'

// App
import App from '@/app'

// Router
import router from '@/router'

// Store
import store from '@/store'

// Import global filters
import '@/filters'

// messages
import messages from './lang'

// Include all css files
import '@/lib/main-css'

Vue.config.productionTip = false

// Plugins
Vue.use(VueI18n)

Vue.component('GmapCluster', GmapCluster)

// Api Service (VueAxios)
ApiService.init()

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'es', // set locale
  messages // set locale messages
})

Vue.use(VueGoogleMaps, {
  load: {
    key: AppConfig.googleMapsKey,
    libraries: 'places,drawing,visualization'
  },
  installComponents: true
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
