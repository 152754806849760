import { FilesService } from '@/api/api.service'
import { FILES_UPLOAD_FILE } from '@/store/actions.type'
import { FILES_SET_FILE } from '@/store/mutations.type'

const initialState = {
  file: {}
}

export const state = Object.assign({}, initialState)

function extractFileData(file, type) {
  const data = {
    name: file.name,
    mimeType: file.type,
    type: type,
    size: file.size
  }
  return data
}

const actions = {
  async [FILES_UPLOAD_FILE]({ commit }, { file, type }) {
    const fileData = extractFileData(file, type)
    const result = await FilesService.createFile(fileData)
    const { data } = await FilesService.uploadFile({
      file,
      uploadUrl: result.data.uploadUrl
    })
    commit(FILES_SET_FILE, result.data)
    return data
  }
}

const mutations = {
  [FILES_SET_FILE](state, file) {
    state.file = file
  }
}

const getters = {
  getFilesFile: state => {
    return state.file
  },
  getFilesIsLoading: state => {
    return state.isLoading
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
