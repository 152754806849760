import { PlacesService } from '@/api/api.service'
import {
  PLACES_GET_PLACES,
  PLACES_GET_PLACE,
  PLACES_DELETE_USER,
  PLACES_ADD_USER,
  PLACES_UPDATE_PLACE,
  PLACES_DELETE_PLACE,
  PLACES_GET_PLACE_TYPES,
  PLACES_CREATE_PLACE
} from '@/store/actions.type'
import {
  PLACES_SET_PLACES,
  PLACES_SET_SELECTED_PLACE,
  PLACES_REMOVE_SELECTED_PLACE,
  PLACES_SET_PLACE,
  PLACES_SET_PLACE_TYPES,
  PLACES_GET_START,
  PLACES_GET_END,
  PLACES_SET_SELECTED_PLACE_TYPE,
  PLACES_SET_LOCATION,
  PLACES_SET_IS_EDITING_PLACE
} from '@/store/mutations.type'

const initialState = {
  places: {},
  place: {},
  selectedPlace: {},
  isSelected: false,
  isLoading: true,
  isEditingPlace: false,
  placeTypes: [],
  selectedPlaceType: null,
  location: null
}

export const state = Object.assign({}, initialState)

const actions = {
  async [PLACES_GET_PLACES]({ commit }) {
    commit(PLACES_GET_START)
    const { data } = await PlacesService.getPlaces()
    commit(PLACES_SET_PLACES, data)
    commit(PLACES_GET_END)
  },

  async [PLACES_GET_PLACE]({ commit }, placeId) {
    commit(PLACES_GET_START)
    const { data } = await PlacesService.getPlace(placeId)
    commit(PLACES_SET_PLACE, data)
    commit(PLACES_GET_END)
  },
  async [PLACES_GET_PLACE_TYPES]({ commit }) {
    commit(PLACES_GET_START)
    const { data } = await PlacesService.getPlaceTypes()
    commit(PLACES_SET_PLACE_TYPES, data)
    commit(PLACES_GET_END)
    return data
  },
  async [PLACES_CREATE_PLACE]({ commit }, place) {
    commit(PLACES_GET_START)
    const { data } = await PlacesService.createPlace(place)
    commit(PLACES_GET_END)
    return data
  },

  async [PLACES_UPDATE_PLACE](
    context,
    { placeId, name, description, location }
  ) {
    const place = {}
    if (name) place.name = name
    if (description) place.description = description
    if (location) place.location = location
    await PlacesService.updatePlace(placeId, place)
  },

  async [PLACES_DELETE_USER](context, { userId, placeId }) {
    await PlacesService.deletePlaceUser(placeId, userId)
  },

  async [PLACES_DELETE_PLACE](context, placeId) {
    await PlacesService.deletePlace(placeId)
  },

  async [PLACES_ADD_USER](context, { userId, placeId }) {
    await PlacesService.addPlaceUser(placeId, userId)
  },
  async [PLACES_ADD_USER](context, { userId, placeId }) {
    await PlacesService.addPlaceUser(placeId, userId)
  }
}

const mutations = {
  [PLACES_GET_START](state) {
    state.isLoading = true
  },
  [PLACES_GET_END](state) {
    state.isLoading = false
  },
  [PLACES_SET_PLACES](state, places) {
    // @TODO: Refactor
    const points = places
      .filter(place => place.location.geometry.type === 'Point')
      .map(point => {
        point.position = {
          lat: point.center.latitude,
          lng: point.center.longitude
        }
        return point
      })
    const polygons = places
      .filter(place => place.location.geometry.type === 'Polygon')
      .map(polygon => {
        const paths = []
        const coords = polygon.location.geometry.coordinates
        for (let i = 0; i < coords.length; i++) {
          const path = []
          for (let j = 0; j < coords[i].length; j++) {
            const point = coords[i][j]
            path.push({ lat: point[1], lng: point[0] })
          }
          paths.push(path)
        }
        polygon.paths = paths
        return polygon
      })
    state.places = [...points, ...polygons]
    state.isLoading = false
  },

  [PLACES_SET_PLACE](state, place) {
    if (place.location.geometry.type === 'Point') {
      place.position = {
        lat: place.center.latitude,
        lng: place.center.longitude
      }
    }

    if (place.location.geometry.type === 'Polygon') {
      const paths = []
      const coords = place.location.geometry.coordinates
      for (let i = 0; i < coords.length; i++) {
        const path = []
        for (let j = 0; j < coords[i].length; j++) {
          const point = coords[i][j]
          path.push({ lat: point[1], lng: point[0] })
        }
        paths.push(path)
      }
      place.paths = paths
    }

    state.place = place
    state.isLoading = false
  },
  [PLACES_SET_SELECTED_PLACE](state, issue) {
    state.selectedPlace = issue
    state.isSelected = true
  },
  [PLACES_REMOVE_SELECTED_PLACE](state) {
    state.selectedPlace = null
    state.place = {}
    state.isSelected = false
  },
  [PLACES_SET_PLACE_TYPES](state, placeTypes) {
    state.placeTypes = placeTypes
  },
  [PLACES_SET_LOCATION](state, location) {
    state.location = location
  },
  [PLACES_SET_SELECTED_PLACE_TYPE](state, placeType) {
    state.selectedPlaceType = placeType
  },
  [PLACES_SET_IS_EDITING_PLACE](state, isEditingPlace) {
    state.isEditingPlace = isEditingPlace
  }

}

const getters = {
  getPlacesPlaces: state => {
    return state.places
  },
  getPlacesIsLoading: state => {
    return state.isLoading
  },
  getPlaceSelected: state => {
    return state.selectedPlace
  },
  getPlacesIsSelected: state => {
    return state.isSelected
  },
  getPlacesPoints: state => {
    if (!Array.isArray(state.places)) return []
    return state.places.filter(
      place => place.location.geometry.type === 'Point'
    )
  },
  getPlacesPolygons: state => {
    if (!Array.isArray(state.places)) return []
    return state.places.filter(
      place => place.location.geometry.type === 'Polygon'
    )
  },
  getPlacesPlace: state => {
    return state.place
  },
  getPlacesPlaceTypes: state => {
    return state.placeTypes
  },
  getPlacesLocation: state => {
    return state.location
  },
  getPlacesSelectedPlaceType: state => {
    return state.selectedPlaceType
  },
  getPlacesIsEditingPlace: state => {
    return state.isEditingPlace
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
