<template>
  <v-app id="inspire" style="background:#fff">
    <!-- <div v-if="getIsAuthenticated"> -->
      <router-view></router-view>
    <!-- </div> -->
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'app',
  computed: {
    ...mapGetters(['getIsAuthenticated'])
  }
}
</script>
