import { IssueTypesService } from '@/api/api.service'
import {
  ISSUE_TYPES_GET_ISSUE_TYPES,
  ISSUE_TYPES_GET_ISSUE_TYPE,
  ISSUE_TYPES_CREATE_ISSUE_TYPE,
  ISSUE_TYPES_UPDATE_ISSUE_TYPE
} from '@/store/actions.type'
import {
  ISSUE_TYPES_SET_ISSUE_TYPES,
  ISSUE_TYPES_SET_ISSUE_TYPE,
  ISSUE_TYPES_GET_START,
  ISSUE_TYPES_GET_END
} from '@/store/mutations.type'

const initialState = {
  issueTypes: [],
  issueType: {},
  isLoading: true
}

export const state = Object.assign({}, initialState)

const actions = {
  async [ISSUE_TYPES_GET_ISSUE_TYPES]({ commit }) {
    commit(ISSUE_TYPES_GET_START)
    const { data } = await IssueTypesService.getIssueTypes()
    commit(ISSUE_TYPES_SET_ISSUE_TYPES, data)
    commit(ISSUE_TYPES_GET_END)
    return data
  },

  async [ISSUE_TYPES_GET_ISSUE_TYPE]({ commit }, issueTypeId) {
    commit(ISSUE_TYPES_GET_START)
    const { data } = await IssueTypesService.getIssueType(issueTypeId)
    commit(ISSUE_TYPES_SET_ISSUE_TYPE, data)
    commit(ISSUE_TYPES_GET_END)
    return data
  },

  async [ISSUE_TYPES_CREATE_ISSUE_TYPE]({ commit }, issueType) {
    await IssueTypesService.createIssueType(issueType)
  },
  async [ISSUE_TYPES_UPDATE_ISSUE_TYPE]({ commit }, issueType) {
    await IssueTypesService.updateIssueType(issueType)
  }
}

const mutations = {
  [ISSUE_TYPES_GET_START](state) {
    state.isLoading = true
  },
  [ISSUE_TYPES_GET_END](state) {
    state.isLoading = false
  },
  [ISSUE_TYPES_SET_ISSUE_TYPES](state, issueTypes) {
    state.issueTypes = issueTypes
  },
  [ISSUE_TYPES_SET_ISSUE_TYPE](state, issueType) {
    state.issueType = issueType
  }
}

const getters = {
  getIssueTypesIssueTypes: state => {
    return state.issueTypes
  },
  getIssueTypesIsLoading: state => {
    return state.isLoading
  },
  getIssueTypesIssueType: state => {
    return state.issueType
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
