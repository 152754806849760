import es from './es'
import en from './en'

export default {
  en: {
    message: en
  },
  es: {
    message: es
  }
}
