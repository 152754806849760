<template>
  <div v-show="isError">
    <v-alert type="error" outlined>
      {{message}}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'BaseError',
  props: {
    error: {
      type: Object
    }
  },
  data() {
    return {
      isError: false,
      message: null
    }
  },
  watch: {
    error: function() {
      this.getMessage()
    }
  },
  methods: {
    getMessage() {
      if (this.error) {
        this.isError = true
        this.message = this.error.message
      } else {
        this.isError = false
      }
    }
  },
  components: {}
}
</script>
