import { format } from 'date-fns'
import es from 'date-fns/locale/es'

const DateFormat = function(isoString) {
  if (!isoString) return '-'
  const utcDate = Date.parse(isoString)
  const formatted = format(utcDate, 'dd MMM yyy', {
    locale: es
  })
  return formatted || ''
}

export default function(isoString) {
  if (!isoString) return '-'
  const utcDate = Date.parse(isoString)
  const formatted = format(utcDate, 'dd MMM yyy HH:mm', {
    locale: es
  })
  return formatted || ''
}

export {
  DateFormat
}
