import {
  propEq,
  propOr,
  pathOr,
  includes,
  mergeDeepLeft,
  isEmpty,
  find,
  compose,
  whereEq,
  map,
  not
} from 'ramda'
import to from 'await-to-js'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this
    var args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export {
  propEq,
  propOr,
  pathOr,
  includes,
  mergeDeepLeft,
  isEmpty,
  find,
  to,
  compose,
  addDays,
  format,
  formatDistanceToNow,
  differenceInMinutes,
  debounce,
  whereEq,
  map,
  not
}
