import { GroupsService } from '@/api/api.service'
import { GROUPS_SET_GROUPS, GROUPS_SELECT_GROUP, GROUPS_SELECT_GROUP_WITH_DATA } from '@/store/mutations.type'
import {
  GROUPS_GET_GROUPS,
  GROUPS_GET_GROUP,
  GROUPS_REMOVE_GROUP,
  GROUPS_CREATE_GROUP,
  GROUPS_UPDATE_GROUP,
  GROUPS_REMOVE_GROUP_MEMBER,
  GROUPS_ADD_GROUP_MEMBER
} from '@/store/actions.type'

const initialState = {
  groups: [],
  selectedGroup: {}
}

export const state = Object.assign({}, initialState)

export const actions = {

  async [GROUPS_CREATE_GROUP] (context, group) {
    const response = await GroupsService.createGroup(group)
    if (response.status === 201) {
      context.commit(GROUPS_CREATE_GROUP, response.data)
    }
  },
  async [GROUPS_REMOVE_GROUP] (context, group) {
    const response = await GroupsService.removeGroup(group)
    if (response.status === 200) {
      context.commit(GROUPS_REMOVE_GROUP, group)
    }
  },
  async [GROUPS_UPDATE_GROUP] (context, group) {
    const response = await GroupsService.updateGroup(group)
    if (response.status === 200) {
      context.commit(GROUPS_UPDATE_GROUP, response.data)
    }
  },
  async [GROUPS_GET_GROUPS] (context) {
    const { data } = await GroupsService.getGroups()
    context.commit(GROUPS_SET_GROUPS, data)
  },
  async [GROUPS_GET_GROUP] (context, groupId) {
    const { data } = await GroupsService.getGroup(groupId)
    context.commit(GROUPS_SELECT_GROUP_WITH_DATA, data)
  },
  async [GROUPS_REMOVE_GROUP_MEMBER] (context, params) {
    const response = await GroupsService.removeMember(params.group, params.user)
    if (response.status === 200) {
      context.commit(GROUPS_REMOVE_GROUP_MEMBER, params)
    }
  },
  async [GROUPS_ADD_GROUP_MEMBER] (context, params) {
    const response = await GroupsService.addMember(params.group, params.user)
    if (response.status === 200) {
      context.commit(GROUPS_ADD_GROUP_MEMBER, params)
    }
  }
}

export const mutations = {
  [GROUPS_CREATE_GROUP] (state, data) {
    state.groups.push(data)
  },
  [GROUPS_REMOVE_GROUP] (state, group) {
    var index = state.groups.findIndex(g => g.id === group.id)
    state.groups.splice(index, 1)
  },
  [GROUPS_UPDATE_GROUP] (state, group) {
    var index = state.groups.findIndex(g => g.id === group.id)
    state.groups[index] = group
  },
  [GROUPS_SET_GROUPS] (state, groups) {
    state.groups = groups
  },
  [GROUPS_SELECT_GROUP] (state, id) {
    var index = state.groups.findIndex(g => g.id === id)
    state.selectedGroup = state.groups[index]
  },
  [GROUPS_SELECT_GROUP_WITH_DATA] (state, group) {
    state.selectedGroup = group
  },
  [GROUPS_REMOVE_GROUP_MEMBER] (state, params) {
    var userIndex = state.selectedGroup.members.findIndex(u => u.id === params.user.id)
    state.selectedGroup.members.splice(userIndex, 1)
  },
  [GROUPS_ADD_GROUP_MEMBER] (state, params) {
    if (state.selectedGroup.members === null) {
      state.selectedGroup.members = []
    }
    state.selectedGroup.members.push(params.user)
  }
}

export const getters = {
  getAllGroups: state => {
    return state.groups
  },
  getSelectedGroup: state => {
    return state.selectedGroup
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
