export default {
  primary: '#F68628',
  secondary: '#F68628',
  titleHeader: '#a6a6a6',
  accent: '#82B1FF',
  error: '#FF3739',
  info: '#fff',
  success: '#00D014',
  warning: '#FFB70F',
  background: '#292930'
}
