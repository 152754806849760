import Vue from 'vue'
import Vuex from 'vuex'

import files from './modules/files'
import forms from './modules/forms'
import issues from './modules/issues'
import issueTypes from './modules/issue-types'
import issueStatus from './modules/issue-status'
import users from './modules/users'
import sessions from './modules/sessions'
import places from './modules/places'
import settings from './modules/settings'
import groups from './modules/groups'
import fields from './modules/fields'
import timelines from './modules/timelines'
import tracking from './modules/tracking'
import workflows from './modules/workflows'
import application from './modules/application'
import roles from './modules/roles'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    application,
    forms,
    groups,
    users,
    fields,
    sessions,
    issues,
    places,
    settings,
    issueTypes,
    issueStatus,
    files,
    timelines,
    tracking,
    workflows,
    roles
  }
})
