import { ApplicationService } from '@/api/api.service'
import {
  APPLICATION_GET_SETTINGS,
  APPLICATION_UPDATE_SETTINGS
} from '@/store/actions.type'
import { APPLICATION_SET_SETTINGS } from '@/store/mutations.type'

const initialState = {
  settings: []
}

export const state = Object.assign({}, initialState)

const actions = {
  async [APPLICATION_GET_SETTINGS]({ commit }) {
    const { data } = await ApplicationService.getSettings()
    commit(APPLICATION_SET_SETTINGS, data)
  },
  async [APPLICATION_UPDATE_SETTINGS](
    context,
    { androidMinVersion, iosMinVersion, chatbotUrl }
  ) {
    const settings = {}
    if (androidMinVersion) settings.androidMinVersion = androidMinVersion
    if (iosMinVersion) settings.iosMinVersion = iosMinVersion
    if (chatbotUrl) settings.chatbotUrl = chatbotUrl
    await ApplicationService.updateSettings(settings)
  }
}

const mutations = {
  [APPLICATION_SET_SETTINGS](state, settings) {
    state.settings = settings
  }
}

const getters = {
  getApplicationSettings: state => {
    return state.settings
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
