import { SessionsService } from '@/api/api.service'
import JwtService from '@/api/jwt.service'
import { to } from '@/helpers'
import {
  SESSION_CHECK_AUTH, SESSION_GET_ME, SESSION_LOGIN, SESSION_LOGOUT, SESSION_VERIFY_TOKEN
} from '@/store/actions.type'
import {
  SESSIONS_SET_ME, SESSION_PURGE_AUTH, SESSION_SET_AUTH
} from '@/store/mutations.type'

const initialState = {
  user: {},
  isAuthenticated: false
}

export const state = Object.assign({}, initialState)

const actions = {
  async [SESSION_LOGOUT](context, credentials) {
    await SessionsService.logout(credentials)
    context.commit(SESSION_PURGE_AUTH)
  },
  async [SESSION_LOGIN](context, credentials) {
    const { data } = await SessionsService.login(credentials)
    context.commit(SESSION_SET_AUTH, data.accessToken)
    context.dispatch(SESSION_GET_ME)
  },
  async [SESSION_GET_ME](context) {
    const [err, data] = await to(SessionsService.me())
    if (err) {
      context.commit(SESSION_PURGE_AUTH)
    } else {
      context.commit(SESSIONS_SET_ME, data.data)
    }
  },

  async [SESSION_VERIFY_TOKEN] (context, token) {
    await SessionsService.verifyToken(token)
  },

  async [SESSION_CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      await context.dispatch(SESSION_GET_ME)
    } else {
      context.commit(SESSION_PURGE_AUTH)
    }
  }
}

const mutations = {
  [SESSION_SET_AUTH](state, token) {
    JwtService.saveToken(token)
    state.isAuthenticated = true
  },

  [SESSION_PURGE_AUTH](state) {
    JwtService.destroyToken()
    state.isAuthenticated = false
    state.user = {}
  },
  [SESSIONS_SET_ME](state, user) {
    state.user = user
    state.isAuthenticated = true
  }
}

const getters = {
  getIsAuthenticated: state => {
    return state.isAuthenticated
  },
  getSessionUser: state => {
    return state.user
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
